<template>
  <!-- 樂透選單 type2 -->
  <div
    :id="'menu-' + data.block"
    class="menu mb-3"
  >
    <!-- header -->
    <div class="h-14 menu__header d-flex align-center px-4">
      <div class="menu__header--title font-weight-bold icon--text">
        {{ data.data.navbar[0].game_type }}
      </div>
    </div>

    <!-- items -->
    <div>
      <swiper
        ref="gameItem"
        :options="swiperOption"
        class="mySwiper px-4 py-0"
      >
        <swiper-slide
          v-for="game in gameItems"
          :key="game._id"
          class="game__item"
        >
          <div
            class="game__item--card"
          >
            <div
              class="rounded overflow-hidden"
              :style="gradientBg"
              :class="game.status==='0'?'maintenanceBox':''"
            >
              <!-- 維修中 父層要加 :class="game.status==='0'?'maintenanceBox':''"-->
              <maintenance v-if="game.status=='0'" />
              <v-img
                :src="baseImgUrl + game.image_h5"
                height="152"
                class="rounded cursor-pointer"
                @click="lauchGame(game.game_code)"
              />
            </div>
            <span class="subtitle-2 rounded-b w-100 game-name card1--text text-center primary">
              {{ game.title }}
            </span>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import { resStatus } from '../../../../utils/resUtils'
import maintenance from '@/components/maintenance'

export default {
  components: { maintenance },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    swiperOption: {
      slidesPerView: 2.1,
      spaceBetween: 8,
      centeredSlidesBounds: true,

    },
  }),

  computed: {
    ...mapGetters(['baseImgUrl', 'gradientBg']),

    ...mapState({
      isLogin: state => state.user.isLogin,
      isLiffMode: state => state.line.liff.status,
    }),

    gameItems() {
      return this.data.data.navbar[0].items
    },
  },

  methods: {
    ...mapActions(['get_game_url', 'show_alert', 'get_game_url_by_device']),

    /**
     * 取得遊戲 url (成功)
     * @date 2021-07-02
     * @param {string} gameUrl 遊戲連結 url
     */
    getGameUrlSuccess(gameUrl, windowObj) {
      console.log(`Slot 開啟遊戲，liff mode: ${this.isLiffMode}`)
      const ua = navigator.userAgent.toLowerCase()
      console.log(ua)
      console.log('LINE browser: ' + ua.indexOf('line'))

      // liff 模式 or line 開啟
      if (this.isLiffMode || ua.indexOf('line') >= 0) {
        console.log('遊戲嵌入模式')
        // 進入嵌入遊戲頁面
        this.$router.push({
          name: 'launchGame',
          query: {
            url: gameUrl.url,
            redirectUrl: this.$route.path,
          },
        })
      } else {
        // 一般模式
        windowObj.location.href = gameUrl.url
      }
    },

    /**
     * 取得遊戲 url (失敗)
     * @date 2021-07-02
     */
    getGameUrlFail() {
      this.show_alert({ icon: 'fail' })
    },

    /**
     * 取得遊戲 url
     * @date 2021-07-02
     * @param {string} gameCode 遊戲代碼
     */
    async lauchGame(gameCode) {
      // 如果尚未登入
      if (!this.isLogin) {
        return this.show_alert({
          icon: 'fail',
          text: this.$t('flashMessage.loginFirst'),
        })
      }

      const windowObj = window.open('', '_blank')
      const res = await this.get_game_url_by_device({ gamecode: gameCode, is_mobile: 1 })
      resStatus(res, this.getGameUrlSuccess, this.getGameUrlFail, windowObj)
    },

    /**
     * 設置 slide index (供顯示)
     * @date 2021-06-18
     * @param {number} index slider 索引
     */
    setSliderIndex(index) {
      this.sliderIndex = index
    },

    /**
     * 上一頁
     * @date 2021-06-18
     */
    slidePrev() {
      this.$refs.gameItem.$swiper.slidePrev()
      this.setSliderIndex(this.$refs.gameItem.$swiper.activeIndex)
    },

    /**
     * 下一頁功能
     * @date 2021-06-18
     */
    slideNext() {
      this.$refs.gameItem.$swiper.slideNext()
      this.setSliderIndex(this.$refs.gameItem.$swiper.activeIndex)
    },
  },
}
</script>

<style lang="scss" scoped>
.h-14 {
	height: 48px;
}

.menu__header--title {
	font-size: 18px;
}

.game__item--card{
	position: relative;

	.game-name {
		position: absolute;
		bottom: 0;
		left: 0;
		height: 28px;
		line-height: 28px;
	}
}
</style>
